import React from 'react';
import Article from 'components/help/helpArticle';

const Content = () => {
  return (
    <div>
      <p>There are several cases when LiveSession script will not track visitor on your website:</p>
      <ul>
        <li>A visitor who opted out from tracking</li>
        <li>A visitor who disabled JavaScript</li>
        <li>A visitor who disabled Cookies</li>
        <li>A visitor uses an unsupported web browser</li>
        <li>A visitor uses browser blocking plugins (eg. Ghostery)</li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/opt-out-cookie',
    title: 'Opt-out Cookie',
    description: 'How to opt-out from LiveSession tracking',
  },
];

export const frontmatter = {
  metaTitle: 'Why my visitors are excluded?',
  metaDescription: 'Determine why your visitors are excluded from session recording',
  canonical: '/help/visitors-excluded/',
};

const Wrapped = Article(Content);
export default () => (
  <Wrapped
    related={related}
    section="Product"
    title="Which of visitors are excluded from tracking on my website?"
    {...frontmatter}
  />
);
